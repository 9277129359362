.App {
  font-family: sans-serif;
  text-align: center;
}
.art {
  color: #e91e63 !important;
}
.invst {
  color: #00A929  !important;
}
